<template>
  <div id="page-error">
    <v-container class="py-10">
      <div class="d-flex flex-column justify-end align-center">
        <h1 class="display-4 font-weight-medium mb-4">{{ errorStatus }}</h1>

        <h1>{{ errorTitle }}</h1>
        <p>{{ errorMessage }}</p>

        <p v-if="params.pathMatch || params.debug && params.debug.path">
          Zielseite:
          <kbd>{{ params.debug ? params.debug.path : params.pathMatch }}</kbd>
        </p>

        <router-link v-slot="{href, navigate}" to="/" class="mt-2" custom>
          <v-btn :href="href" @click="navigate">Zur Startseite</v-btn>
        </router-link>
      </div>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Error',

    props: {
      status: {
        type: Number,
        default: 400
      },
      title: {
        type: String,
        default: 'error.default.title'
      },
      message: {
        type: String,
        default: 'error.default.message'
      }
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      params() {
        return this.$route.params;
      },
      errorStatus() {
        return this.params.status || this.status;
      },
      errorTitle() {
        return this.params.title || this.title;
      },
      errorMessage() {
        return this.params.message || this.message;
      }
    }
  };
</script>
